import { createAction, props } from '@ngrx/store';

import { ZdlakeCityDestinations, ZdlakeSearchRequest } from '../../models';

export const loadRequest = createAction(
  '[City Destinations] Load Request',
  props<{ request: ZdlakeSearchRequest }>(),
);
export const loadSuccess = createAction(
  '[City Destinations] Load Success',
  props<{ items: ZdlakeCityDestinations[] }>(),
);
export const loadFailure = createAction(
  '[City Destinations] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[City Destinations] Reset State');
